import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'

import { AuthToken } from '../models/AuthToken';
import { GenerateReportsCreate201Response } from '../models/GenerateReportsCreate201Response';
import { GenerateReportsCreate400Response } from '../models/GenerateReportsCreate400Response';
import { GenerateReportsCreate400ResponseErrorsInner } from '../models/GenerateReportsCreate400ResponseErrorsInner';
import { GenerateReportsCreate500Response } from '../models/GenerateReportsCreate500Response';
import { GenerateReportsCreate500ResponseErrorsInner } from '../models/GenerateReportsCreate500ResponseErrorsInner';
import { GenerateReportsCreateRequest } from '../models/GenerateReportsCreateRequest';
import { HonorificTitleEnum } from '../models/HonorificTitleEnum';
import { PatchedUser } from '../models/PatchedUser';
import { SendMail } from '../models/SendMail';
import { SendingMethodEnum } from '../models/SendingMethodEnum';
import { Slip } from '../models/Slip';
import { SlipTypeEnum } from '../models/SlipTypeEnum';
import { SlipsSignedUrlRetrieve200Response } from '../models/SlipsSignedUrlRetrieve200Response';
import { Supplier } from '../models/Supplier';
import { SuppliersSendMailCreate200Response } from '../models/SuppliersSendMailCreate200Response';
import { User } from '../models/User';
import { ObservableAuthTokenApi } from './ObservableAPI';

import { AuthTokenApiRequestFactory, AuthTokenApiResponseProcessor} from "../apis/AuthTokenApi";
export class PromiseAuthTokenApi {
    private api: ObservableAuthTokenApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthTokenApiRequestFactory,
        responseProcessor?: AuthTokenApiResponseProcessor
    ) {
        this.api = new ObservableAuthTokenApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param username
     * @param password
     * @param token
     */
    public authTokenCreateWithHttpInfo(username: string, password: string, token: string, _options?: Configuration): Promise<HttpInfo<AuthToken>> {
        const result = this.api.authTokenCreateWithHttpInfo(username, password, token, _options);
        return result.toPromise();
    }

    /**
     * @param username
     * @param password
     * @param token
     */
    public authTokenCreate(username: string, password: string, token: string, _options?: Configuration): Promise<AuthToken> {
        const result = this.api.authTokenCreate(username, password, token, _options);
        return result.toPromise();
    }


}



import { ObservableGenerateReportsApi } from './ObservableAPI';

import { GenerateReportsApiRequestFactory, GenerateReportsApiResponseProcessor} from "../apis/GenerateReportsApi";
export class PromiseGenerateReportsApi {
    private api: ObservableGenerateReportsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: GenerateReportsApiRequestFactory,
        responseProcessor?: GenerateReportsApiResponseProcessor
    ) {
        this.api = new ObservableGenerateReportsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param [generateReportsCreateRequest]
     */
    public generateReportsCreateWithHttpInfo(generateReportsCreateRequest?: GenerateReportsCreateRequest, _options?: Configuration): Promise<HttpInfo<GenerateReportsCreate201Response>> {
        const result = this.api.generateReportsCreateWithHttpInfo(generateReportsCreateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param [generateReportsCreateRequest]
     */
    public generateReportsCreate(generateReportsCreateRequest?: GenerateReportsCreateRequest, _options?: Configuration): Promise<GenerateReportsCreate201Response> {
        const result = this.api.generateReportsCreate(generateReportsCreateRequest, _options);
        return result.toPromise();
    }


}



import { ObservableSchemaApi } from './ObservableAPI';

import { SchemaApiRequestFactory, SchemaApiResponseProcessor} from "../apis/SchemaApi";
export class PromiseSchemaApi {
    private api: ObservableSchemaApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SchemaApiRequestFactory,
        responseProcessor?: SchemaApiResponseProcessor
    ) {
        this.api = new ObservableSchemaApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param [format]
     * @param [lang]
     */
    public schemaRetrieveWithHttpInfo(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'ckb' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', _options?: Configuration): Promise<HttpInfo<{ [key: string]: any; }>> {
        const result = this.api.schemaRetrieveWithHttpInfo(format, lang, _options);
        return result.toPromise();
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param [format]
     * @param [lang]
     */
    public schemaRetrieve(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'ckb' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', _options?: Configuration): Promise<{ [key: string]: any; }> {
        const result = this.api.schemaRetrieve(format, lang, _options);
        return result.toPromise();
    }


}



import { ObservableSlipsApi } from './ObservableAPI';

import { SlipsApiRequestFactory, SlipsApiResponseProcessor} from "../apis/SlipsApi";
export class PromiseSlipsApi {
    private api: ObservableSlipsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SlipsApiRequestFactory,
        responseProcessor?: SlipsApiResponseProcessor
    ) {
        this.api = new ObservableSlipsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param slipId
     */
    public slipsSignedUrlRetrieveWithHttpInfo(slipId: number, _options?: Configuration): Promise<HttpInfo<SlipsSignedUrlRetrieve200Response>> {
        const result = this.api.slipsSignedUrlRetrieveWithHttpInfo(slipId, _options);
        return result.toPromise();
    }

    /**
     * @param slipId
     */
    public slipsSignedUrlRetrieve(slipId: number, _options?: Configuration): Promise<SlipsSignedUrlRetrieve200Response> {
        const result = this.api.slipsSignedUrlRetrieve(slipId, _options);
        return result.toPromise();
    }


}



import { ObservableSuppliersApi } from './ObservableAPI';

import { SuppliersApiRequestFactory, SuppliersApiResponseProcessor} from "../apis/SuppliersApi";
export class PromiseSuppliersApi {
    private api: ObservableSuppliersApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SuppliersApiRequestFactory,
        responseProcessor?: SuppliersApiResponseProcessor
    ) {
        this.api = new ObservableSuppliersApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param [ordering] Ordering: [\&#39;pk\&#39;, \&#39;code\&#39;, \&#39;sending_method\&#39;]
     * @param [slipsIsnull]
     */
    public suppliersListWithHttpInfo(ordering?: string, slipsIsnull?: boolean, _options?: Configuration): Promise<HttpInfo<Array<Supplier>>> {
        const result = this.api.suppliersListWithHttpInfo(ordering, slipsIsnull, _options);
        return result.toPromise();
    }

    /**
     * @param [ordering] Ordering: [\&#39;pk\&#39;, \&#39;code\&#39;, \&#39;sending_method\&#39;]
     * @param [slipsIsnull]
     */
    public suppliersList(ordering?: string, slipsIsnull?: boolean, _options?: Configuration): Promise<Array<Supplier>> {
        const result = this.api.suppliersList(ordering, slipsIsnull, _options);
        return result.toPromise();
    }

    /**
     * @param supplierId
     * @param sendMail
     */
    public suppliersSendMailCreateWithHttpInfo(supplierId: number, sendMail: SendMail, _options?: Configuration): Promise<HttpInfo<SuppliersSendMailCreate200Response>> {
        const result = this.api.suppliersSendMailCreateWithHttpInfo(supplierId, sendMail, _options);
        return result.toPromise();
    }

    /**
     * @param supplierId
     * @param sendMail
     */
    public suppliersSendMailCreate(supplierId: number, sendMail: SendMail, _options?: Configuration): Promise<SuppliersSendMailCreate200Response> {
        const result = this.api.suppliersSendMailCreate(supplierId, sendMail, _options);
        return result.toPromise();
    }


}



import { ObservableUsersApi } from './ObservableAPI';

import { UsersApiRequestFactory, UsersApiResponseProcessor} from "../apis/UsersApi";
export class PromiseUsersApi {
    private api: ObservableUsersApi

    public constructor(
        configuration: Configuration,
        requestFactory?: UsersApiRequestFactory,
        responseProcessor?: UsersApiResponseProcessor
    ) {
        this.api = new ObservableUsersApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public usersListWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<User>>> {
        const result = this.api.usersListWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public usersList(_options?: Configuration): Promise<Array<User>> {
        const result = this.api.usersList(_options);
        return result.toPromise();
    }

    /**
     */
    public usersMeRetrieveWithHttpInfo(_options?: Configuration): Promise<HttpInfo<User>> {
        const result = this.api.usersMeRetrieveWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public usersMeRetrieve(_options?: Configuration): Promise<User> {
        const result = this.api.usersMeRetrieve(_options);
        return result.toPromise();
    }

    /**
     * @param username
     * @param [patchedUser]
     */
    public usersPartialUpdateWithHttpInfo(username: string, patchedUser?: PatchedUser, _options?: Configuration): Promise<HttpInfo<User>> {
        const result = this.api.usersPartialUpdateWithHttpInfo(username, patchedUser, _options);
        return result.toPromise();
    }

    /**
     * @param username
     * @param [patchedUser]
     */
    public usersPartialUpdate(username: string, patchedUser?: PatchedUser, _options?: Configuration): Promise<User> {
        const result = this.api.usersPartialUpdate(username, patchedUser, _options);
        return result.toPromise();
    }

    /**
     * @param username
     */
    public usersRetrieveWithHttpInfo(username: string, _options?: Configuration): Promise<HttpInfo<User>> {
        const result = this.api.usersRetrieveWithHttpInfo(username, _options);
        return result.toPromise();
    }

    /**
     * @param username
     */
    public usersRetrieve(username: string, _options?: Configuration): Promise<User> {
        const result = this.api.usersRetrieve(username, _options);
        return result.toPromise();
    }

    /**
     * @param username
     * @param user
     */
    public usersUpdateWithHttpInfo(username: string, user: User, _options?: Configuration): Promise<HttpInfo<User>> {
        const result = this.api.usersUpdateWithHttpInfo(username, user, _options);
        return result.toPromise();
    }

    /**
     * @param username
     * @param user
     */
    public usersUpdate(username: string, user: User, _options?: Configuration): Promise<User> {
        const result = this.api.usersUpdate(username, user, _options);
        return result.toPromise();
    }


}



