import {
	ApiException,
	GenerateReportsCreate400Response,
	type GenerateReportsCreate400ResponseErrorsInner,
	GenerateReportsCreate500Response,
	type GenerateReportsCreate500ResponseErrorsInner,
} from '../api'

type ErrorResponse = {
	type: string
	errors: Array<ErrorsInner>
}
type ErrorsInner = {
	code: string
	attr?: string
	detail: string
}

const unexpectedMessege = '予期しないエラーが発生しました。'
export const createErrorMessage = (error: Error): string => {
	if (error instanceof ApiException) {
		if (
			error.body instanceof GenerateReportsCreate400Response ||
			error.body instanceof GenerateReportsCreate500Response
		) {
			return error.body.errors
				? joinedErrorMessage(error.body.errors)
				: unexpectedMessege
		}
		if (typeof error.body === 'string') {
			try {
				const body = JSON.parse(error.body) as ErrorResponse
				return joinedErrorMessage(body.errors)
			} catch (e) {
				return unexpectedMessege
			}
		}
	}
	return unexpectedMessege
}

type ErrorsInnerTypes =
	| ErrorsInner
	| GenerateReportsCreate400ResponseErrorsInner
	| GenerateReportsCreate500ResponseErrorsInner
const joinedErrorMessage = (errors: Array<ErrorsInnerTypes>): string => {
	return errors
		.map((e) => `コード[${e.code}] : ${e.attr ?? ''} ${e.detail}`)
		.join('\n')
}
