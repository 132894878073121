import type { Dayjs } from 'dayjs'

const formatterMap = {
	ISO: 'YYYY-MM-DD',
	ISO_YEAR_MONTH: 'YYYY-MM',
	PRINT_YEAR_MONTH: 'YYYY年MM月',
	PRINT_MONTH: 'M月',
}

export const formatDate = (
	day: Dayjs | undefined | null,
	formatterKey: keyof typeof formatterMap,
): string => {
	if (!day) {
		return ''
	}
	return day.format(formatterMap[formatterKey])
}
