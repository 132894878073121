import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import 'dayjs/locale/ja'

// day.jsの設定
dayjs.locale('ja')

// Import the generated route tree
import { routeTree } from './routeTree.gen'

// Create a new router instance
const router = createRouter({ routeTree })

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

// Render the app
const rootElement = document.getElementById('root')
if (!rootElement) {
	throw new Error('Root element not found')
}

// sentry setup
if (import.meta.env.MODE !== 'development') {
	Sentry.init({
		dsn: 'https://00be7d252b7ce9b06a5dc4bbd7be56ad@o1155853.ingest.us.sentry.io/4508322491138048',
		environment: import.meta.env.MODE,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.tanstackRouterBrowserTracingIntegration(router),
		],
		tracePropagationTargets: ['localhost'],
	})
}

const queryClient = new QueryClient()
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement)
	root.render(
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<ChakraProvider>
					<RouterProvider router={router} />
				</ChakraProvider>
			</QueryClientProvider>
		</StrictMode>,
	)
}
