import { useDisclosure } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import apiClient from '../apiClient'
import { createErrorMessage } from '../helpers/errors'

export interface ReportFormParam {
	yearMonth: string
	issueDate: string
}

const useReport = ({ completed }: { completed: () => void }) => {
	const {
		isOpen: isOpenAlert,
		onClose: onAlertClose,
		onOpen: onAlertOpen,
	} = useDisclosure()
	const [alertTitle, setAlertTitle] = useState('')
	const [alertMessage, setAlertMessage] = useState('')
	const reportMutation = useMutation({
		mutationFn: (param: ReportFormParam) => {
			const reportRequest = {
				reportPeriod: param.yearMonth,
				issueDate: param.issueDate,
			}
			return apiClient.generateReportsApi().generateReportsCreate(reportRequest)
		},
		onSuccess: async () => {
			await completed()
		},
		onError: async (error) => {
			setAlertTitle('出力に失敗しました')
			setAlertMessage(createErrorMessage(error))
			onAlertOpen()
			await completed()
		},
	})

	return {
		isOpenAlert,
		onAlertClose,
		alertTitle,
		alertMessage,
		reportMutation,
	}
}

export default useReport
