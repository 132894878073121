/**
 * krypton API
 * Documentation of API endpoints of krypton
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SlipTypeEnum } from '../models/SlipTypeEnum';
import { HttpFile } from '../http/http';

export class Slip {
    'id': number;
    'slipDate': string;
    'issueDate': string;
    'slipType': SlipTypeEnum;
    'filePath': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly mapping: {[index: string]: string} | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "slipDate",
            "baseName": "slip_date",
            "type": "string",
            "format": "date"
        },
        {
            "name": "issueDate",
            "baseName": "issue_date",
            "type": "string",
            "format": "date"
        },
        {
            "name": "slipType",
            "baseName": "slip_type",
            "type": "SlipTypeEnum",
            "format": ""
        },
        {
            "name": "filePath",
            "baseName": "file_path",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Slip.attributeTypeMap;
    }

    public constructor() {
    }
}


