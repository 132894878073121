import { Highlight } from '@chakra-ui/react'
import type { SendingMethodEnum } from '../api'

type SendingMethodMap = {
	[key in SendingMethodEnum]: string
}
export const sendingMethodTextMap: SendingMethodMap = {
	'1': 'メール',
	'2': '郵送',
	'3': 'FAX',
	'9': '送付不要',
} as const

const sendingMethodColorMap: SendingMethodMap = {
	'1': '#4433A4',
	'2': '#822727',
	'3': '#22543D',
	'9': '#1A202C',
}

const sendingMethodBGMap: SendingMethodMap = {
	'1': '#E9D8FD',
	'2': '#FED7D7',
	'3': '#C6F6D5',
	'9': '#EDF2F7',
}

interface Props {
	sendingMethod: SendingMethodEnum
}

export default function SendingMethodHighlight(props: Props) {
	const sendingMethod = props.sendingMethod
	const sendingMethodText = sendingMethodTextMap[sendingMethod]
	const sendingMethodBG = sendingMethodBGMap[sendingMethod]
	const sendingMethodColor = sendingMethodColorMap[sendingMethod]
	const styles = {
		px: '2',
		py: '1',
		rounded: 'md',
		bg: sendingMethodBG,
		color: sendingMethodColor,
	}

	return (
		<Highlight query={sendingMethodText} styles={styles}>
			{sendingMethodText}
		</Highlight>
	)
}
