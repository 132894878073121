import { useMutation, useQuery } from '@tanstack/react-query'
import { redirect, useNavigate } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { ApiException, type User } from '../api'
import apiClient from '../apiClient'
import { createErrorMessage } from '../helpers/errors'

const ACCESSTOKEN_KEY = 'access_token'

export const initAPIToken = () => {
	const token = localStorage.getItem(ACCESSTOKEN_KEY)
	if (token) {
		apiClient.setToken(token)
		return 'success'
	}
	return 'fail'
}

export const authRoute = () => {
	const result = initAPIToken()
	if (result === 'fail') {
		throw redirect({ to: '/login' })
	}
}

export const clearAPIToken = () => {
	localStorage.removeItem(ACCESSTOKEN_KEY)
}

export type LoginParams = {
	username: string
	password: string
}

const useAuth = () => {
	const [error, setError] = useState<string | null>(null)
	const navigate = useNavigate()

	const {
		data: user,
		isLoading,
		error: invalidCurrentUser,
	} = useQuery<User | null, Error>({
		queryKey: ['currentUser'],
		queryFn: async () => {
			const response = await apiClient.usersApi().usersMeRetrieve()
			return response as User
		},
		enabled: initAPIToken() === 'success',
	})

	useEffect(() => {
		if (invalidCurrentUser !== null) {
			navigate({ to: '/login' })
		}
	}, [invalidCurrentUser, navigate])

	const login = async (p: LoginParams) => {
		const response = await apiClient
			.authTokenApi()
			.authTokenCreate(p.username, p.password, '')
		return response.token
	}

	const loginMutation = useMutation({
		mutationFn: login,
		onSuccess: (token) => {
			localStorage.setItem(ACCESSTOKEN_KEY, token)
			navigate({ to: '/' })
		},
		onError: (_) => {
			setError(
				'ログイン情報が登録されていません。IDまたはパスワードをご確認ください。',
			)
		},
	})

	const logout = () => {
		localStorage.removeItem(ACCESSTOKEN_KEY)
		location.reload()
	}

	return {
		loginMutation,
		logout,
		user,
		isLoading,
		error,
		resetError: () => setError(null),
	}
}

export default useAuth
