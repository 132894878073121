import { Button, Divider, HStack, Input, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { formatDate } from '../helpers/date'
import type { ReportFormParam } from '../hooks/useReport'

interface Props {
	onSubmit: (param: ReportFormParam) => void
}
export default function ReportForm(props: Props) {
	const [yearMonth, setYearMonth] = useState('')
	const [issueDate, setIssueDate] = useState(formatDate(dayjs(), 'ISO'))
	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault()
		const param: ReportFormParam = {
			yearMonth: yearMonth,
			issueDate: issueDate,
		}
		props.onSubmit(param)
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<HStack>
					<HStack>
						<Text whiteSpace="nowrap">年月度:</Text>
						<Input
							type="month"
							value={yearMonth}
							onChange={(e) => setYearMonth(e.target.value)}
							required
						/>
					</HStack>

					<HStack>
						<Text whiteSpace="nowrap">発行日:</Text>
						<Input
							type="date"
							value={issueDate}
							onChange={(e) => setIssueDate(e.target.value)}
							required
						/>
					</HStack>

					<HStack>
						<Button type="submit" ml={2} color="white" bg="#319795">
							出力開始
						</Button>
					</HStack>
				</HStack>
			</form>

			<Divider my={4} />
		</>
	)
}
