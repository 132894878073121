import { IconButton, useDisclosure } from '@chakra-ui/react'
import { MdMailOutline } from 'react-icons/md'
import type { Supplier } from '../api'
import SendMailModal from './SendMailModal'
import { sendingMethodTextMap } from './SendingMethodHighlight'
interface Props {
	supplier: Supplier
}
export default function MailFaxCell(props: Props) {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { supplier } = props
	const showMailButton =
		sendingMethodTextMap[supplier.sendingMethod] === 'メール'
	const showFaxNumber = sendingMethodTextMap[supplier.sendingMethod] === 'FAX'
	const invalidEmailAddress =
		!supplier.emailAddress1 &&
		!supplier.emailAddress2 &&
		!supplier.emailAddress3
	const invalidFaxNumber = !supplier.faxNumber
	return (
		<>
			{showMailButton &&
				(invalidEmailAddress ? (
					<span>アドレス未登録</span>
				) : (
					<>
						<IconButton
							aria-label="メール送信"
							icon={<MdMailOutline />}
							color="white"
							bg="#319795"
							onClick={onOpen}
						/>
						<SendMailModal
							supplier={supplier}
							isOpen={isOpen}
							onClose={onClose}
						/>
					</>
				))}
			{showFaxNumber &&
				(invalidFaxNumber ? (
					<span>FAX番号未登録</span>
				) : (
					<span>{supplier.faxNumber}</span>
				))}
		</>
	)
}
