import { Center, Spinner } from '@chakra-ui/react'

export default function Loading() {
	return (
		<>
			<Center
				position="fixed"
				top="0"
				left="0"
				width="100vw"
				height="100vh"
				bg="rgba(0, 0, 0, 0.1)"
			>
				<Spinner
					size="xl"
					thickness="4px"
					speed="0.65s"
					color="#3182CE"
					emptyColor="#E6EBF2"
				/>
			</Center>
		</>
	)
}
