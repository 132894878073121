import { Button, useDisclosure } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { IoDocumentTextOutline } from 'react-icons/io5'
import type { ApiException, Slip, SlipTypeEnum } from '../api'
import apiClient from '../apiClient'
import AlertModal from './AlertModal'

type SlipTypeTextMap = {
	[key in SlipTypeEnum]: string
}
export const slipTypeTextMap: SlipTypeTextMap = {
	payment_report: '支払通知書',
	report_by_product: '明細(商品別)',
	report_by_day: '明細(日付別)',
} as const

interface Props {
	slip: Slip
}
export default function DownloadSlipButton(props: Props) {
	const { isOpen, onClose, onOpen } = useDisclosure()

	const slipMutation = useMutation({
		mutationFn: async (slip: Slip) => {
			return await apiClient.slipsApi().slipsSignedUrlRetrieve(slip.id)
		},
		onSuccess: (response) => {
			window.open(response.signedUrl, '_brank')
		},
		onError: (error: ApiException<string>) => {
			console.error('on open slip failed:', error)
			onOpen()
		},
	})

	return (
		<>
			<Button
				leftIcon={<IoDocumentTextOutline />}
				variant="outline"
				color="#319795"
				onClick={() => slipMutation.mutate(props.slip)}
				isLoading={slipMutation.isPending}
			>
				{slipTypeTextMap[props.slip.slipType]}
			</Button>
			<AlertModal
				isError={true}
				title="PDFのダウンロードに失敗しました"
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	)
}
