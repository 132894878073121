import { useDisclosure } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import type { Supplier } from '../api'
import apiClient from '../apiClient'
import { createErrorMessage } from '../helpers/errors'

export type SendMailParams = {
	supplier: Supplier
	body: string
}

const useMail = ({ completed }: { completed: () => void }) => {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const [alertTitle, setAlertTitle] = useState('')
	const [alertMessage, setAlertMessage] = useState('')
	const sendMailMutation = useMutation({
		mutationFn: async (p: SendMailParams) => {
			const request = {
				body: p.body,
			}
			return await apiClient
				.suppliersApi()
				.suppliersSendMailCreate(p.supplier.id, request)
		},
		onSuccess: () => {
			setAlertTitle('メールを送信しました')
			setAlertMessage('')
			onOpen()
			completed()
		},
		onError: (error) => {
			setAlertTitle('メールを送信できませんでした')
			setAlertMessage(createErrorMessage(error))
			onOpen()
		},
	})

	return {
		isOpenAlert: isOpen,
		onAlertClose: onClose,
		alertTitle,
		alertMessage,
		sendMailMutation,
	}
}

export default useMail
