import {
	HStack,
	Heading,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import type { Supplier } from '../api'
import { formatDate } from '../helpers/date'
import DownloadSlipButton from './DownloadSlipButton'
import MailFaxCell from './MailFaxCell'
import SendingMethodHighlight, {} from './SendingMethodHighlight'

interface Props {
	suppliers: Supplier[]
}
const createSlipDateText = (suppliers: Supplier[]) => {
	const supplierWithSlip = suppliers.find(
		(supplier) => supplier.slips.length > 0,
	)
	if (!supplierWithSlip) return ''
	const slip = supplierWithSlip.slips[0]
	const formattedSlipDate = formatDate(dayjs(slip.slipDate), 'PRINT_YEAR_MONTH')
	return `出力年月度：${formattedSlipDate}度`
}

export default function SupplierTable(props: Props) {
	return (
		<>
			<Heading size="md">{createSlipDateText(props.suppliers)}</Heading>
			<TableContainer mt={4}>
				<Table variant="simple">
					<Thead bg="#F7FAFC">
						<Tr>
							<Th>仕入先CD</Th>
							<Th>仕入先名</Th>
							<Th>送付方法</Th>
							<Th>PDF</Th>
							<Th>メール・FAX</Th>
						</Tr>
					</Thead>
					<Tbody>
						{props.suppliers.map((supplier) => (
							<Tr key={supplier.id}>
								<Td>{supplier.code}</Td>
								<Td>{supplier.name}</Td>
								<Td>
									<SendingMethodHighlight
										sendingMethod={supplier.sendingMethod}
									/>
								</Td>
								<Td>
									<HStack>
										{supplier.slips.map((slip) => (
											<DownloadSlipButton key={slip.id} slip={slip} />
										))}
									</HStack>
								</Td>
								<Td>
									<MailFaxCell supplier={supplier} />
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	)
}
