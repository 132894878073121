import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { AuthToken } from '../models/AuthToken';
import { GenerateReportsCreate201Response } from '../models/GenerateReportsCreate201Response';
import { GenerateReportsCreate400Response } from '../models/GenerateReportsCreate400Response';
import { GenerateReportsCreate400ResponseErrorsInner } from '../models/GenerateReportsCreate400ResponseErrorsInner';
import { GenerateReportsCreate500Response } from '../models/GenerateReportsCreate500Response';
import { GenerateReportsCreate500ResponseErrorsInner } from '../models/GenerateReportsCreate500ResponseErrorsInner';
import { GenerateReportsCreateRequest } from '../models/GenerateReportsCreateRequest';
import { HonorificTitleEnum } from '../models/HonorificTitleEnum';
import { PatchedUser } from '../models/PatchedUser';
import { SendMail } from '../models/SendMail';
import { SendingMethodEnum } from '../models/SendingMethodEnum';
import { Slip } from '../models/Slip';
import { SlipTypeEnum } from '../models/SlipTypeEnum';
import { SlipsSignedUrlRetrieve200Response } from '../models/SlipsSignedUrlRetrieve200Response';
import { Supplier } from '../models/Supplier';
import { SuppliersSendMailCreate200Response } from '../models/SuppliersSendMailCreate200Response';
import { User } from '../models/User';

import { AuthTokenApiRequestFactory, AuthTokenApiResponseProcessor} from "../apis/AuthTokenApi";
export class ObservableAuthTokenApi {
    private requestFactory: AuthTokenApiRequestFactory;
    private responseProcessor: AuthTokenApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthTokenApiRequestFactory,
        responseProcessor?: AuthTokenApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AuthTokenApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AuthTokenApiResponseProcessor();
    }

    /**
     * @param username
     * @param password
     * @param token
     */
    public authTokenCreateWithHttpInfo(username: string, password: string, token: string, _options?: Configuration): Observable<HttpInfo<AuthToken>> {
        const requestContextPromise = this.requestFactory.authTokenCreate(username, password, token, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.authTokenCreateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param username
     * @param password
     * @param token
     */
    public authTokenCreate(username: string, password: string, token: string, _options?: Configuration): Observable<AuthToken> {
        return this.authTokenCreateWithHttpInfo(username, password, token, _options).pipe(map((apiResponse: HttpInfo<AuthToken>) => apiResponse.data));
    }

}

import { GenerateReportsApiRequestFactory, GenerateReportsApiResponseProcessor} from "../apis/GenerateReportsApi";
export class ObservableGenerateReportsApi {
    private requestFactory: GenerateReportsApiRequestFactory;
    private responseProcessor: GenerateReportsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: GenerateReportsApiRequestFactory,
        responseProcessor?: GenerateReportsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new GenerateReportsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new GenerateReportsApiResponseProcessor();
    }

    /**
     * @param [generateReportsCreateRequest]
     */
    public generateReportsCreateWithHttpInfo(generateReportsCreateRequest?: GenerateReportsCreateRequest, _options?: Configuration): Observable<HttpInfo<GenerateReportsCreate201Response>> {
        const requestContextPromise = this.requestFactory.generateReportsCreate(generateReportsCreateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.generateReportsCreateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param [generateReportsCreateRequest]
     */
    public generateReportsCreate(generateReportsCreateRequest?: GenerateReportsCreateRequest, _options?: Configuration): Observable<GenerateReportsCreate201Response> {
        return this.generateReportsCreateWithHttpInfo(generateReportsCreateRequest, _options).pipe(map((apiResponse: HttpInfo<GenerateReportsCreate201Response>) => apiResponse.data));
    }

}

import { SchemaApiRequestFactory, SchemaApiResponseProcessor} from "../apis/SchemaApi";
export class ObservableSchemaApi {
    private requestFactory: SchemaApiRequestFactory;
    private responseProcessor: SchemaApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SchemaApiRequestFactory,
        responseProcessor?: SchemaApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SchemaApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SchemaApiResponseProcessor();
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param [format]
     * @param [lang]
     */
    public schemaRetrieveWithHttpInfo(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'ckb' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', _options?: Configuration): Observable<HttpInfo<{ [key: string]: any; }>> {
        const requestContextPromise = this.requestFactory.schemaRetrieve(format, lang, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.schemaRetrieveWithHttpInfo(rsp)));
            }));
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param [format]
     * @param [lang]
     */
    public schemaRetrieve(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'ckb' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', _options?: Configuration): Observable<{ [key: string]: any; }> {
        return this.schemaRetrieveWithHttpInfo(format, lang, _options).pipe(map((apiResponse: HttpInfo<{ [key: string]: any; }>) => apiResponse.data));
    }

}

import { SlipsApiRequestFactory, SlipsApiResponseProcessor} from "../apis/SlipsApi";
export class ObservableSlipsApi {
    private requestFactory: SlipsApiRequestFactory;
    private responseProcessor: SlipsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SlipsApiRequestFactory,
        responseProcessor?: SlipsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SlipsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SlipsApiResponseProcessor();
    }

    /**
     * @param slipId
     */
    public slipsSignedUrlRetrieveWithHttpInfo(slipId: number, _options?: Configuration): Observable<HttpInfo<SlipsSignedUrlRetrieve200Response>> {
        const requestContextPromise = this.requestFactory.slipsSignedUrlRetrieve(slipId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.slipsSignedUrlRetrieveWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param slipId
     */
    public slipsSignedUrlRetrieve(slipId: number, _options?: Configuration): Observable<SlipsSignedUrlRetrieve200Response> {
        return this.slipsSignedUrlRetrieveWithHttpInfo(slipId, _options).pipe(map((apiResponse: HttpInfo<SlipsSignedUrlRetrieve200Response>) => apiResponse.data));
    }

}

import { SuppliersApiRequestFactory, SuppliersApiResponseProcessor} from "../apis/SuppliersApi";
export class ObservableSuppliersApi {
    private requestFactory: SuppliersApiRequestFactory;
    private responseProcessor: SuppliersApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SuppliersApiRequestFactory,
        responseProcessor?: SuppliersApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SuppliersApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SuppliersApiResponseProcessor();
    }

    /**
     * @param [ordering] Ordering: [\&#39;pk\&#39;, \&#39;code\&#39;, \&#39;sending_method\&#39;]
     * @param [slipsIsnull]
     */
    public suppliersListWithHttpInfo(ordering?: string, slipsIsnull?: boolean, _options?: Configuration): Observable<HttpInfo<Array<Supplier>>> {
        const requestContextPromise = this.requestFactory.suppliersList(ordering, slipsIsnull, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.suppliersListWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param [ordering] Ordering: [\&#39;pk\&#39;, \&#39;code\&#39;, \&#39;sending_method\&#39;]
     * @param [slipsIsnull]
     */
    public suppliersList(ordering?: string, slipsIsnull?: boolean, _options?: Configuration): Observable<Array<Supplier>> {
        return this.suppliersListWithHttpInfo(ordering, slipsIsnull, _options).pipe(map((apiResponse: HttpInfo<Array<Supplier>>) => apiResponse.data));
    }

    /**
     * @param supplierId
     * @param sendMail
     */
    public suppliersSendMailCreateWithHttpInfo(supplierId: number, sendMail: SendMail, _options?: Configuration): Observable<HttpInfo<SuppliersSendMailCreate200Response>> {
        const requestContextPromise = this.requestFactory.suppliersSendMailCreate(supplierId, sendMail, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.suppliersSendMailCreateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param supplierId
     * @param sendMail
     */
    public suppliersSendMailCreate(supplierId: number, sendMail: SendMail, _options?: Configuration): Observable<SuppliersSendMailCreate200Response> {
        return this.suppliersSendMailCreateWithHttpInfo(supplierId, sendMail, _options).pipe(map((apiResponse: HttpInfo<SuppliersSendMailCreate200Response>) => apiResponse.data));
    }

}

import { UsersApiRequestFactory, UsersApiResponseProcessor} from "../apis/UsersApi";
export class ObservableUsersApi {
    private requestFactory: UsersApiRequestFactory;
    private responseProcessor: UsersApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: UsersApiRequestFactory,
        responseProcessor?: UsersApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new UsersApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new UsersApiResponseProcessor();
    }

    /**
     */
    public usersListWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<User>>> {
        const requestContextPromise = this.requestFactory.usersList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.usersListWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public usersList(_options?: Configuration): Observable<Array<User>> {
        return this.usersListWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<User>>) => apiResponse.data));
    }

    /**
     */
    public usersMeRetrieveWithHttpInfo(_options?: Configuration): Observable<HttpInfo<User>> {
        const requestContextPromise = this.requestFactory.usersMeRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.usersMeRetrieveWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public usersMeRetrieve(_options?: Configuration): Observable<User> {
        return this.usersMeRetrieveWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<User>) => apiResponse.data));
    }

    /**
     * @param username
     * @param [patchedUser]
     */
    public usersPartialUpdateWithHttpInfo(username: string, patchedUser?: PatchedUser, _options?: Configuration): Observable<HttpInfo<User>> {
        const requestContextPromise = this.requestFactory.usersPartialUpdate(username, patchedUser, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.usersPartialUpdateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param username
     * @param [patchedUser]
     */
    public usersPartialUpdate(username: string, patchedUser?: PatchedUser, _options?: Configuration): Observable<User> {
        return this.usersPartialUpdateWithHttpInfo(username, patchedUser, _options).pipe(map((apiResponse: HttpInfo<User>) => apiResponse.data));
    }

    /**
     * @param username
     */
    public usersRetrieveWithHttpInfo(username: string, _options?: Configuration): Observable<HttpInfo<User>> {
        const requestContextPromise = this.requestFactory.usersRetrieve(username, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.usersRetrieveWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param username
     */
    public usersRetrieve(username: string, _options?: Configuration): Observable<User> {
        return this.usersRetrieveWithHttpInfo(username, _options).pipe(map((apiResponse: HttpInfo<User>) => apiResponse.data));
    }

    /**
     * @param username
     * @param user
     */
    public usersUpdateWithHttpInfo(username: string, user: User, _options?: Configuration): Observable<HttpInfo<User>> {
        const requestContextPromise = this.requestFactory.usersUpdate(username, user, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (const middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (const middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.usersUpdateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param username
     * @param user
     */
    public usersUpdate(username: string, user: User, _options?: Configuration): Observable<User> {
        return this.usersUpdateWithHttpInfo(username, user, _options).pipe(map((apiResponse: HttpInfo<User>) => apiResponse.data));
    }

}
