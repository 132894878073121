import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import type { Supplier } from '../api'
import { formatDate } from '../helpers/date'
import useMail, { type SendMailParams } from '../hooks/useMail'
import AlertModal from './AlertModal'
import DownloadSlipButton from './DownloadSlipButton'

interface Props {
	supplier: Supplier
	isOpen: boolean
	onClose: () => void
}
const createJoinedEmailAddress = (supplier: Supplier) => {
	const emailAddresses = [
		supplier.emailAddress1,
		supplier.emailAddress2,
		supplier.emailAddress3,
	]
	return emailAddresses.filter((v) => v).join(',')
}
const createEmailSubject = (supplier: Supplier) => {
	const slips = supplier.slips
	if (slips.length === 0) return ''
	const slipDate = dayjs(slips[0].slipDate)
	const formattedMonth = formatDate(slipDate, 'PRINT_MONTH')
	return `${formattedMonth}度地産地消　販売実績`
}

const SendMailModal = (props: Props) => {
	const supplier = props.supplier
	const emailAddress = createJoinedEmailAddress(supplier)
	const emailSubject = createEmailSubject(supplier)
	const [emailBody, setEmailBody] = useState(
		createEmailBody(supplier.name, supplier.honorificTitle),
	)
	const {
		sendMailMutation,
		isOpenAlert,
		onAlertClose,
		alertTitle,
		alertMessage,
	} = useMail({ completed: props.onClose })
	const { isPending, isError, mutate } = sendMailMutation

	const onSendEmail = async () => {
		const params: SendMailParams = {
			supplier: supplier,
			body: emailBody,
		}
		await mutate(params)
	}

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				onClose={props.onClose}
				size="4xl"
				closeOnOverlayClick={false}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader />
					<ModalBody>
						<Text>件名: {emailSubject}</Text>
						<Text mt={4}>To: {emailAddress}</Text>
						<Textarea
							placeholder="本文"
							mt={4}
							resize="vertical"
							rows={15}
							value={emailBody}
							onChange={(e) => setEmailBody(e.target.value)}
							isDisabled={isPending}
						/>
						<HStack mt={6}>
							<Text>添付：</Text>
							{supplier.slips.map((slip) => (
								<DownloadSlipButton key={slip.id} slip={slip} />
							))}
						</HStack>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={props.onClose} isDisabled={isPending}>
							キャンセル
						</Button>
						<Button
							color="white"
							bg="#319795"
							onClick={onSendEmail}
							isLoading={isPending}
						>
							送信
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<AlertModal
				isError={isError}
				title={alertTitle}
				message={alertMessage}
				isOpen={isOpenAlert}
				onClose={onAlertClose}
			/>
		</>
	)
}

export default SendMailModal

const createEmailBody = (name: string, honorific: string) => `
${name} ${honorific}

いつもお世話になります。
伊賀越(株)の岸田と申します。
早速ですが、販売実績とお支払通知を添付にてご連絡をさせていただきます。
ご不明点等がございましたらご一報いただけますと幸いです。
どうぞ宜しくお願いいたします。


以上

■□□■□□■□□■□□■□□■□□■□□■□□■□□■□□■□□■□□■
伊賀越株式会社　販売事業部
岸田　美和
〒518-0822
三重県伊賀市問屋町67
TEL　0595-41-0020
FAX　0595-24-1838
mail address：gyomu-bu@igagoe.co.jp
■□□■□□■□□■□□■□□■□□■□□■□□■□□■□□■□□■□□■`
