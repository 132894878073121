import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	CloseButton,
	Modal,
	ModalContent,
	Spacer,
} from '@chakra-ui/react'

interface Props {
	isError: boolean
	title?: string
	message?: string
	isOpen: boolean
	onClose: () => void
}
export default function AlertModal(props: Props) {
	const status = props.isError ? 'error' : 'success'
	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onClose}
			size="4xl"
			closeOnOverlayClick={false}
		>
			<ModalContent>
				<Alert status={status} borderRadius="md">
					<AlertIcon />
					<Box>
						{props.title && <AlertTitle>{props.title}</AlertTitle>}
						{props.message && (
							<AlertDescription>{props.message}</AlertDescription>
						)}
					</Box>
					<Spacer />
					<CloseButton
						alignSelf="flex-start"
						position="relative"
						right={-1}
						top={-1}
						onClick={props.onClose}
					/>
				</Alert>
			</ModalContent>
		</Modal>
	)
}
