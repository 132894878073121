// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile, HttpInfo} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { GenerateReportsCreate400Response } from '../models/GenerateReportsCreate400Response';
import { GenerateReportsCreate500Response } from '../models/GenerateReportsCreate500Response';
import { SendMail } from '../models/SendMail';
import { Supplier } from '../models/Supplier';
import { SuppliersSendMailCreate200Response } from '../models/SuppliersSendMailCreate200Response';

/**
 * no description
 */
export class SuppliersApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param ordering Ordering: [\&#39;pk\&#39;, \&#39;code\&#39;, \&#39;sending_method\&#39;]
     * @param slipsIsnull 
     */
    public async suppliersList(ordering?: string, slipsIsnull?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;



        // Path Params
        const localVarPath = '/api/v1/suppliers/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (ordering !== undefined) {
            requestContext.setQueryParam("ordering", ObjectSerializer.serialize(ordering, "string", ""));
        }

        // Query Params
        if (slipsIsnull !== undefined) {
            requestContext.setQueryParam("slips__isnull", ObjectSerializer.serialize(slipsIsnull, "boolean", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param supplierId 
     * @param sendMail 
     */
    public async suppliersSendMailCreate(supplierId: number, sendMail: SendMail, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'supplierId' is not null or undefined
        if (supplierId === null || supplierId === undefined) {
            throw new RequiredError("SuppliersApi", "suppliersSendMailCreate", "supplierId");
        }


        // verify required parameter 'sendMail' is not null or undefined
        if (sendMail === null || sendMail === undefined) {
            throw new RequiredError("SuppliersApi", "suppliersSendMailCreate", "sendMail");
        }


        // Path Params
        const localVarPath = '/api/v1/suppliers/{supplier_id}/send-mail/'
            .replace('{' + 'supplier_id' + '}', encodeURIComponent(String(supplierId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(sendMail, "SendMail", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class SuppliersApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to suppliersList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async suppliersListWithHttpInfo(response: ResponseContext): Promise<HttpInfo<Array<Supplier> >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<Supplier> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<Supplier>", ""
            ) as Array<Supplier>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<Supplier> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<Supplier>", ""
            ) as Array<Supplier>;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to suppliersSendMailCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async suppliersSendMailCreateWithHttpInfo(response: ResponseContext): Promise<HttpInfo<SuppliersSendMailCreate200Response >> {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SuppliersSendMailCreate200Response = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SuppliersSendMailCreate200Response", ""
            ) as SuppliersSendMailCreate200Response;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: GenerateReportsCreate400Response = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GenerateReportsCreate400Response", ""
            ) as GenerateReportsCreate400Response;
            throw new ApiException<GenerateReportsCreate400Response>(response.httpStatusCode, "", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: GenerateReportsCreate500Response = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GenerateReportsCreate500Response", ""
            ) as GenerateReportsCreate500Response;
            throw new ApiException<GenerateReportsCreate500Response>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SuppliersSendMailCreate200Response = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SuppliersSendMailCreate200Response", ""
            ) as SuppliersSendMailCreate200Response;
            return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
