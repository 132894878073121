/**
 * krypton API
 * Documentation of API endpoints of krypton
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HonorificTitleEnum } from '../models/HonorificTitleEnum';
import { SendingMethodEnum } from '../models/SendingMethodEnum';
import { Slip } from '../models/Slip';
import { HttpFile } from '../http/http';

export class Supplier {
    'id': number;
    'code': string;
    'name': string;
    'nameToFile': string;
    'sendingMethod': SendingMethodEnum;
    'zipcode'?: string;
    'address1'?: string;
    'address2'?: string;
    'address3'?: string;
    'faxNumber'?: string;
    'emailAddress1'?: string;
    'emailAddress2'?: string;
    'emailAddress3'?: string;
    'insertSentence'?: string;
    'invoiceRegistrationNumber'?: string;
    'honorificTitle': HonorificTitleEnum;
    'paymentListExclusion'?: string;
    'annotation1'?: string;
    'annotation2'?: string;
    'annotation3'?: string;
    'slips': Array<Slip>;

    static readonly discriminator: string | undefined = undefined;

    static readonly mapping: {[index: string]: string} | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "nameToFile",
            "baseName": "name_to_file",
            "type": "string",
            "format": ""
        },
        {
            "name": "sendingMethod",
            "baseName": "sending_method",
            "type": "SendingMethodEnum",
            "format": ""
        },
        {
            "name": "zipcode",
            "baseName": "zipcode",
            "type": "string",
            "format": ""
        },
        {
            "name": "address1",
            "baseName": "address_1",
            "type": "string",
            "format": ""
        },
        {
            "name": "address2",
            "baseName": "address_2",
            "type": "string",
            "format": ""
        },
        {
            "name": "address3",
            "baseName": "address_3",
            "type": "string",
            "format": ""
        },
        {
            "name": "faxNumber",
            "baseName": "fax_number",
            "type": "string",
            "format": ""
        },
        {
            "name": "emailAddress1",
            "baseName": "email_address_1",
            "type": "string",
            "format": "email"
        },
        {
            "name": "emailAddress2",
            "baseName": "email_address_2",
            "type": "string",
            "format": "email"
        },
        {
            "name": "emailAddress3",
            "baseName": "email_address_3",
            "type": "string",
            "format": "email"
        },
        {
            "name": "insertSentence",
            "baseName": "insert_sentence",
            "type": "string",
            "format": ""
        },
        {
            "name": "invoiceRegistrationNumber",
            "baseName": "invoice_registration_number",
            "type": "string",
            "format": ""
        },
        {
            "name": "honorificTitle",
            "baseName": "honorific_title",
            "type": "HonorificTitleEnum",
            "format": ""
        },
        {
            "name": "paymentListExclusion",
            "baseName": "payment_list_exclusion",
            "type": "string",
            "format": ""
        },
        {
            "name": "annotation1",
            "baseName": "annotation_1",
            "type": "string",
            "format": ""
        },
        {
            "name": "annotation2",
            "baseName": "annotation_2",
            "type": "string",
            "format": ""
        },
        {
            "name": "annotation3",
            "baseName": "annotation_3",
            "type": "string",
            "format": ""
        },
        {
            "name": "slips",
            "baseName": "slips",
            "type": "Array<Slip>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Supplier.attributeTypeMap;
    }

    public constructor() {
    }
}


