import { createFileRoute, redirect } from '@tanstack/react-router'
import LoginForm from '../components/LoginForm'
import { initAPIToken } from '../hooks/useAuth'

export const Route = createFileRoute('/login')({
	component: Login,
	beforeLoad: () => {
		const result = initAPIToken()
		if (result === 'success') {
			throw redirect({ to: '/' })
		}
	},
})

function Login() {
	return <LoginForm />
}
