/**
 * krypton API
 * Documentation of API endpoints of krypton
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* * `1` - email * `2` - posting * `3` - fax * `9` - unnecessary
*/
export enum SendingMethodEnum {
    _1 = '1',
    _2 = '2',
    _3 = '3',
    _9 = '9'
}
