import { Outlet, createRootRoute } from '@tanstack/react-router'
import { useMatches } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import Header from '../components/Header'

export const Route = createRootRoute({
	component: RootComponent,
})

function RootComponent() {
	const isNoHeader = useMatches().some((match) => match.fullPath === '/login')
	const isNoDevtools = import.meta.env.PROD

	return (
		<>
			{!isNoHeader && <Header />}
			<Outlet />
			{!isNoDevtools && <TanStackRouterDevtools />}
		</>
	)
}
