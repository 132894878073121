import {
	Box,
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	styled,
} from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import useAuth from '../hooks/useAuth'

const Container = styled('div', {
	baseStyle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
})
const adminUrl = `${import.meta.env.VITE_API_ENDPOINT}/${import.meta.env.VITE_ADMIN_TOOL_PATH}`

export default function Header() {
	const { user, logout } = useAuth()

	return (
		<Box
			as="header"
			bg="white"
			w="100%"
			p={4}
			color="#319795"
			borderBottom="2px solid"
			borderBottomColor="#E2E8F0"
		>
			<Container>
				<Text as="b">伊賀越　業務管理システム</Text>
				<Spacer />
				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<FaChevronDown />}
						size="sm"
						color="#7E7E7E"
						bg="white"
						_hover={{ bg: 'white' }}
						_active={{ bg: 'white' }}
					>
						<Text>{user?.name}</Text>
					</MenuButton>
					<MenuList>
						<MenuItem
							as="a"
							href={adminUrl}
							target="_blank"
							rel="noopener noreferrer"
							color="#4A5568"
							_focus={{ bg: '#1967D2', color: 'white' }}
						>
							マスタ管理
						</MenuItem>
						<MenuItem
							onClick={logout}
							color="#4A5568"
							_focus={{ bg: '#1967D2', color: 'white' }}
						>
							ログアウト
						</MenuItem>
					</MenuList>
				</Menu>
			</Container>
		</Box>
	)
}
