import {
	AuthTokenApi,
	type Configuration,
	GenerateReportsApi,
	type HttpLibrary,
	type RequestContext,
	ResponseContext,
	ServerConfiguration,
	SlipsApi,
	SuppliersApi,
	UsersApi,
	createConfiguration,
} from './api'
import { type Observable, from } from './api/rxjsStub'
import { clearAPIToken } from './hooks/useAuth'

const serverConfiguration = new ServerConfiguration(
	import.meta.env.VITE_API_ENDPOINT,
	{},
)
class FetchLib implements HttpLibrary {
	send(request: RequestContext): Observable<ResponseContext> {
		const method = request.getHttpMethod().toString()
		const body = request.getBody()
		const url = request.getUrl().includes('?')
			? request.getUrl()
			: `${request.getUrl()}/`
		const resultPromise = fetch(url, {
			method: method,
			body: body,
			headers: request.getHeaders(),
			credentials: 'omit',
			mode: 'cors',
		}).then((resp) => {
			const headers: { [name: string]: string } = {}
			resp.headers.forEach((value: string, name: string) => {
				headers[name] = value
			})
			const body = {
				text: () => resp.text(),
				binary: () => resp.blob(),
			}
			return new ResponseContext(resp.status, headers, body)
		})
		return from<Promise<ResponseContext>>(resultPromise)
	}
}

const buildConfiguration = (token?: string) => {
	return createConfiguration({
		baseServer: serverConfiguration,
		httpApi: new FetchLib(),
		authMethods: {
			tokenAuth: token ? `Token ${token}` : undefined,
		},
		middleware: [
			{
				pre: (context: RequestContext) => {
					return from(Promise.resolve(context))
				},
				post: (context: ResponseContext) => {
					const status = context.httpStatusCode
					if (status === 401 || status === 403) {
						clearAPIToken()
						location.reload()
					}
					return from(Promise.resolve(context))
				},
			},
		],
	})
}

let configuration: Configuration = buildConfiguration()

export default {
	usersApi: () => {
		return new UsersApi(configuration)
	},
	authTokenApi: () => {
		return new AuthTokenApi(configuration)
	},
	setToken: (token: string) => {
		configuration = buildConfiguration(token)
	},
	generateReportsApi: () => {
		return new GenerateReportsApi(configuration)
	},
	suppliersApi: () => {
		return new SuppliersApi(configuration)
	},
	slipsApi: () => {
		return new SlipsApi(configuration)
	},
}
