import { useQuery } from '@tanstack/react-query'
import { HonorificTitleEnum, SendingMethodEnum, type Supplier } from '../api'
import apiClient from '../apiClient'

export const emptySupplier: Supplier = {
	id: 0,
	code: '',
	name: '',
	nameToFile: '',
	sendingMethod: SendingMethodEnum._9,
	zipcode: '',
	address1: '',
	address2: '',
	address3: '',
	faxNumber: '',
	emailAddress1: '',
	emailAddress2: '',
	emailAddress3: '',
	insertSentence: '',
	invoiceRegistrationNumber: '',
	honorificTitle: HonorificTitleEnum.御中,
	paymentListExclusion: '',
	annotation1: '',
	annotation2: '',
	annotation3: '',
	slips: [],
}

const useSuppliers = () => {
	const {
		data: suppliers,
		isFetching,
		refetch,
	} = useQuery<Supplier[], Error>({
		queryKey: ['suppliers'],
		queryFn: async () => {
			return await apiClient
				.suppliersApi()
				.suppliersList('sending_method,code', false)
		},
		initialData: [],
	})

	return {
		suppliers,
		isFetching,
		refetch,
	}
}

export default useSuppliers
