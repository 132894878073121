/**
 * krypton API
 * Documentation of API endpoints of krypton
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* * `様` - 様 * `御中` - 御中
*/
export enum HonorificTitleEnum {
    '様' = '様',
    '御中' = '御中'
}
