import {
	Button,
	Card,
	CardBody,
	Container,
	FormControl,
	FormErrorMessage,
	Icon,
	Image,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	useBoolean,
} from '@chakra-ui/react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { IoLockClosed, IoPerson } from 'react-icons/io5'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import logo from '../assets/images/logo.jpg'
import useAuth, { type LoginParams } from '../hooks/useAuth'

export default function LoginForm() {
	const [show, setShow] = useBoolean()
	const { loginMutation, error, resetError } = useAuth()
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<LoginParams>({
		mode: 'onBlur',
		criteriaMode: 'all',
		defaultValues: {
			username: '',
			password: '',
		},
	})

	const submitHandler: SubmitHandler<LoginParams> = async (data) => {
		if (isSubmitting) return
		resetError()
		await loginMutation.mutateAsync(data)
	}

	const onSubmit = handleSubmit(submitHandler)
	return (
		<>
			<Container
				as="form"
				onSubmit={onSubmit}
				h="100vh"
				maxW="sm"
				alignItems="stretch"
				justifyContent="center"
				gap={4}
				centerContent
			>
				<Image src={logo} alt="Logo" />
				<FormControl id="username" isInvalid={!!errors.username || !!error}>
					<InputGroup>
						<InputLeftElement pointerEvents="none" color="#CFD6DE">
							<IoPerson />
						</InputLeftElement>
						<Input
							data-testid="input-username"
							{...register('username')}
							id="username"
							type="text"
							placeholder="ユーザーネーム"
							_placeholder={{ color: '#A6B1C6', opacity: 1 }}
							required
						/>
					</InputGroup>
					{errors.username && (
						<FormErrorMessage>{errors.username.message}</FormErrorMessage>
					)}
				</FormControl>
				<FormControl id="password" isInvalid={!!error}>
					<InputGroup>
						<InputLeftElement pointerEvents="none" color="#CFD6DE">
							<IoLockClosed />
						</InputLeftElement>
						<Input
							data-testid="input-password"
							{...register('password')}
							type={show ? 'text' : 'password'}
							placeholder="パスワード"
							_placeholder={{ color: '#A6B1C6', opacity: 1 }}
							required
						/>
						<InputRightElement
							color="ui.dim"
							_hover={{
								cursor: 'pointer',
							}}
						>
							<Icon
								as={show ? MdVisibilityOff : MdVisibility}
								onClick={setShow.toggle}
								aria-label={show ? 'Hide password' : 'Show password'}
							/>
						</InputRightElement>
					</InputGroup>
					{error && <FormErrorMessage>{error}</FormErrorMessage>}
				</FormControl>
				<Button
					data-testid="btn-login"
					type="submit"
					isLoading={isSubmitting}
					color="white"
					bg="#319795"
					_hover={{ bg: '#3DBCB8' }}
					_loading={{ bg: '#3DBCB8' }}
				>
					ログイン
				</Button>
			</Container>
		</>
	)
}
