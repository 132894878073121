import { Container } from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'
import AlertModal from '../components/AlertModal'
import Loading from '../components/Loading'
import ReportForm from '../components/ReportForm'
import SupplierTable from '../components/SupplierTable'
import { authRoute } from '../hooks/useAuth'
import useReport, { type ReportFormParam } from '../hooks/useReport'
import useSuppliers from '../hooks/useSuppliers'

export const Route = createFileRoute('/')({
	component: Index,
	beforeLoad: authRoute,
})

function Index() {
	const { suppliers, isFetching, refetch } = useSuppliers()
	const {
		reportMutation,
		isOpenAlert,
		onAlertClose,
		alertTitle,
		alertMessage,
	} = useReport({ completed: refetch })
	const { isPending, isError, mutate } = reportMutation

	const handleSubmit = async (param: ReportFormParam) => {
		await mutate(param)
	}

	return (
		<Container px={10} py={4} maxW="100%">
			<ReportForm onSubmit={handleSubmit} />
			{suppliers.length > 0 && <SupplierTable suppliers={suppliers} />}

			{(isPending || isFetching) && <Loading />}
			<AlertModal
				isError={isError}
				title={alertTitle}
				message={alertMessage}
				isOpen={isOpenAlert}
				onClose={onAlertClose}
			/>
		</Container>
	)
}
